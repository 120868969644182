import React from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="Stránka sa nenašla" />
    <section className="menu-bg-coko" style={{ height: '100vh' }}>
      <Container>
        <Row>
          <Col style={{ textAlign: 'center' }} className="pt-5" md={12}>
            <h1>Stránka sa nenašla</h1>
            <p>Museli ste zablúdiť</p>
          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
);

export default NotFoundPage;
